var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "dompurify-html",
            rawName: "v-dompurify-html",
            value: _vm.content,
            expression: "content",
          },
        ],
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }