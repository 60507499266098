<template>
  <div>
    <div v-dompurify-html="content" />

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      content: null,
    }
  },
  created() {
    this.getTotalServiceTerms()
  },
  methods: {
    getTotalServiceTerms() {
      axios.get(`/fu/terms/service`, {
      })
        .then(rs => {
          this.content = rs.data.contents
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
  },
}
</script>
